<template>
  <div class="btn-popup pull-right">
    <b-modal
      id="modal-1"
      :title ="title"
      @ok="onSave"
      ok-title="Save"
      ok-variant="primary"
    >
      <form class="needs-validation">
        <div class="form">
          <div class="form-group">
            <label for="validationCustom01" class="mb-1">Service Name :</label>
            <input
              class="form-control"
              id="validationCustom01"
              type="text"
              v-model="service.name"
            />
          </div>
          <div class="form-group">
            <label for="validationCustom02" class="mb-1">Service uint :</label>
            <input
                class="form-control"
                id="validationCustom02"
                type="text"
                v-model="service.unit"
            />
          </div>
          <div class="form-group">
            <label for="validationCustom03" class="mb-1">Service price :</label>
            <input
                class="form-control"
                id="validationCustom03"
                type="text"
                v-model="service.price"
            />
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script lang="ts">

import {computed, defineComponent, ref} from "vue";

export default defineComponent({
  name: "GlobalCategoryModal",
  components: {

  },
  props: {
    serviceType: {
      type: Object,
      required: true
    },
    title: String
  },
  setup(props, {emit}){
    const service = computed(() => props.serviceType);

    const onSave = () => {
      emit('formData', service.value);
    }


    return {
      onSave,
      service
    }
  }
});

</script>
<style>

</style>
<template>
  <div>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs main="Proiecte" title="Proiecte" />
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>Proiecte</h5>
            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import statusIcon from "../../components/featherIcons/status-icon.vue";


export default defineComponent({
  name: "Proiecte",
  components: {
    statusIcon,
  },
  props: {

  },
  setup(props, { emit }) {
    return {

    }
  }
});

</script>

<style lang="scss" scoped>
table.text-center * {
  text-align: center;
}
</style>
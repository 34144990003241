import axios from 'axios';
import config from '../../../config.json'
import item from "vee-validate/dist/rules.umd";
const ax = axios.create({
    baseURL: config.baseUrl,
});
const url = 'action.php';

const state = {
    userList: [],
    login: ''
}
const getters = {
    getLogin(state) {
        return state.login;
    },
    getUsers(state) {
        return state.userList;
    }
}
const actions = {
    getLogin(context, item) {
        if(item){
            let formData = new FormData();
            formData.append('post_case', 'login');
            formData.append('email', item.email);
            formData.append('password', item.password);
            return axios({
                method: 'post',
                url: '../action.php',
                data: formData,
                config: {headers: {'Content-Type': 'multipart/form-data'}}
            })
            .then(function (response) {
                context.commit('getLogin', response);
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
        }
    },

    getUsers(context) {
        let formData = new FormData();
        formData.append('post_case', 'getUsers');
        return axios({
            method: 'post',
            url: '../action.php',
            data: formData,
            config: {headers: {'Content-Type': 'multipart/form-data'}}
        })
        .then(function (response) {
            console.log(response);
            context.commit('getUsers', response);
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
    },

    saveUser(contex, item){
        let formData = new FormData();
        formData.append('post_case', 'saveUser');
        formData.append('email', item.email);
        formData.append('password', item.password);
        formData.append('fname', item.fname);
        formData.append('lname', item.lname);
        formData.append('phone', item.phone);/*
        formData.append('status', item.status);
        formData.append('permission', item.permission);*/
        console.log(formData);
        return axios({
            method: 'post',
            url: '../action.php',
            data: formData,
            config: {headers: {'Content-Type': 'multipart/form-data'}}
        })
        .then(function (response) {
            console.log(response);
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
    }


}
const mutations = {
    getLogin(state, payload) {
        state.login = payload.data;
    },
    getUsers(state, payload) {
        state.userList = payload.data;
    }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
<template>
  <div class="btn-popup pull-right">
    <b-modal
        id="modal-addService"
        title ="Selecteaza sreviciul"
        @ok="serviciSelected"
        ok-title="Save"
        ok-variant="primary"
    >
      <div>
        <b-form-select v-model="selected" :options="options"></b-form-select>
        <div class="mt-3">Selected: <strong>{{ selected }}</strong></div>
      </div>
      <div class="form-group">
        <label for="startdate">Start date</label>
        <b-form-datepicker
            id="startdate"
            v-model="startdate"
            locale="ro"
            placeholder="YYYY-MM-DD"
            type="text"
            autocomplete="off"
            class="mb-2">

        </b-form-datepicker>

      </div>
    </b-modal>
  </div>
</template>

<script lang="ts">

import {computed, defineComponent, ref} from "vue";
import axios from "axios";

export default defineComponent({
  name: "addService",
  components: {

  },
  props: {
    servicii: {
      type: Array,
      required: true
    }
  },
  setup(props, {emit}){
    const selected = ref();
    const options = computed(() => props.servicii);
    const startdate = ref();



    const serviciSelected = () => {
      const data = computed(() => {
        return {
          selected: selected.value,
          startdate: startdate.value
        }
      })
      emit('saveServici', data);

    }

    return {
      serviciSelected,
      startdate,
      options,
      selected
    }
  }
});

</script>
<style>

</style>
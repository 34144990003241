import axios from 'axios';
import config from '../../../config.json'
import item from "vee-validate/dist/rules.umd";
const ax = axios.create({
    baseURL: config.baseUrl,
});
const url = 'action.php';

const state = {
    serviceList: []
}

const getters = {
    getServiceList: (state) => {
        return state.serviceList;
    }
}

const actions = {
    getServiceList(context) {
        let formData = new FormData();
        formData.append('post_case', 'servicetype');
        axios({
            method: 'post',
            url: '../action.php',
            data: formData,
            config: {headers: {'Content-Type': 'multipart/form-data'}}
        })
            .then(function (response) {

                context.commit('getServiceList', response);
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
    },
    saveServiceType: (context, item) => {
        let formData = new FormData();
        formData.append('post_case', 'saveservicetype');
        formData.append('id', item.id);
        formData.append('name', item.name);
        formData.append('unit', item.unit);
        formData.append('price', item.price);
        return axios({
            method: 'post',
            url: '../action.php',
            data: formData,
            config: {headers: {'Content-Type': 'multipart/form-data'}}
        })
            .then(function (response) {

            })
            .catch(function (response) {
                console.log(response);
            });
    },
    deleteServiceType: (context, item) => {
        let formData = new FormData();
        formData.append('post_case', 'deleteservicetype');
        formData.append('id', item);
        return axios({
            method: 'post',
            url: '../action.php',
            data: formData,
            config: {headers: {'Content-Type': 'multipart/form-data'}}
        })
            .then(function (response) {

            })
            .catch(function (response) {
                console.log(response);
            });
    }

}

const mutations = {
    getServiceList(state, payload) {
        state.serviceList = payload.data;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
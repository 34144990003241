<template>
  <div>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs main="Digital" title="Clients details" />
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">

            <div class="card-header" style="display: flex; justify-content: space-between;">
              <div style="display: flex; justify-content: space-between;">
                <router-link to="/clients/clients-list">
                  <i data-feather="arrow-left" style="color: #ffffff; margin-right: 25px;">
                    <feather type="arrow-left"></feather>
                  </i></router-link>
                <h5>Detalii - {{client.firstname}} {{client.lastname}} - {{uuids}}</h5>
              </div>
              <DocumentModal
                  @saveDocs = "saveDocs"
                  :etapa="selectedEtapa"
                  title="dddd"
                  ></DocumentModal>
              <add-service
                  @saveServici="saveServici"
                  :servicii="addserv"
              ></add-service>
              <proiectModal
                  @saveProiect="saveProiect"
                  :proiectData="editData"
                  :title="modalTitle"
              />
              <b-button v-b-modal.modal-1 :variant="'primary'" @click="addNewProiect()">Adaugare proiect</b-button>
            </div>
          </div>
          <div class="card-body">
            <div class="accordion" role="tablist" v-if="proiecte.length > 0" v-for="(item, index) in proiecte" :key="item.pid">
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <div style="display:flex; justify-content: space-between; width: 100%; " >
                    <div style="display: flex; justify-content: space-between; cursor: pointer;" v-b-toggle="'accordion'+index">
                      <span style="font-size: 18px; color: #ffffff; padding: 10px;">{{item.pname}}</span>
                      <span style="color: aliceblue; font-size: 18px; padding: 10px;">{{item.startdate}} - {{item.enddate}}</span>
                    </div>
                    <div>
                      <b-button-group>
                        <b-button v-b-modal.modal-addService variant="success" @click="addServices(item.id)">Adaugare servicii</b-button>
                        <b-button variant="primary" @click="deleteProiect(item.id)">
                            Stergere
                        </b-button>
                      </b-button-group>
                    </div>
                  </div>
                </b-card-header>
                <b-collapse :id="'accordion'+index" :accordion="'proiect-accordion'+index" role="tabpanel">
                  <b-card-body>
                    <b-card-text>
                      <b-tabs content-class="mt-3">
                        <b-tab title="Servicii" active>
                          <Servicii></Servicii>
                        </b-tab>
                        <b-tab title="Etape">

                          <div class="accordion" role="tablist" v-for="(etapa, idx) in getEtape(item.pid)" :key="etapa.eid">
                            <b-card no-body class="mb-1">
                              <b-card-header header-tag="header" class="p-1" role="tab">
                                <div style="display:flex; justify-content: space-between; width: 100%; " >
                                  <div style="display: flex; justify-content: space-between; cursor: pointer;" v-b-toggle="'etape'+etapa.eid">
                                    <span class="etape" >{{etapa.etapeName}}</span>
                                    <span style="color: aliceblue; font-size: 18px; padding: 10px;">{{etapa.start}} -> {{etapa.end}}</span>
                                  </div>
                                  <div>
                                    <b-button variant="outline-secondary" size="sm" @click="modifEtapa(etapa.eid)">Modificare</b-button>
                                    <b-button v-b-modal.modal-doc variant="outline-warning" size="sm" @click="addDocument(etapa)">Adaugare documente</b-button>

                                  </div>
                                </div>
                              </b-card-header>
                              <b-collapse :id="'etape'+etapa.eid" accordion="my-accordion" role="tabpanel">
                                <b-table
                                    striped
                                    hover
                                    head-variant="dark"
                                    :fields="tablefields"
                                    :items="getDocs(etapa.id)"></b-table>
                              </b-collapse>
                            </b-card>
                          </div>
                        </b-tab>
                      </b-tabs>

                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>


            </div>
            <div v-else><h5>Nu sunt proiecte adaugate</h5></div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, ref} from "vue";
import statusIcon from "../../components/featherIcons/status-icon.vue";
import axios from "axios";
import proiectModal from "@/components/modals/proiectModal.vue";
import EtapeModal from "@/components/modals/EtapeModal.vue";
import Servicii from "@/components/servicii/Servicii.vue";
import AddService from "@/components/modals/addService.vue";
import {uuid} from 'vue-uuid';
import DocumentModal from "@/components/modals/documents.vue";

export default defineComponent({
  name: "ClientDetails",
  components: {
    DocumentModal,
    AddService,
    EtapeModal,
    statusIcon,
    proiectModal,
    Servicii
  },
  props: {
    id: {
      type: String,
      required: true,
    }
  },
  setup(props, { emit }) {
    const client = ref({
      firstname: ''
    });
    const proiecte = ref();
    const modalTitle = ref();
    const proiectid = ref();
    const servicii = ref();
    const addserv = ref([]);
    const etape = ref();
    const selectedEtapa = ref({});
    const servicebyproiect = ref();
    const eid = ref('');
    const docs = ref([]);
    const euuid = ref();

    const tablefields = [
      { key: "nume", label: "Denumire document", sortable: true },
      { key: "start", label: "Data document", sortable: true },
      { key: "end", label: "Data modificarii", sortable: true },
      { key: "link", label: "Link document", sortable: false },
      { key: "actions" }
    ];

    const editData = ref({
      id: null,
      name: null,
      startdate: null,
      enddate: null
    });

    const uuids = computed(() => uuid.v1());

    const getClient = () => {
      axios.post('../action.php', {
        post_case: 'getClientById',
        id: props.id
      },
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }).then((response)=>{
            client.value = response.data[0];
      })
    }
    getClient();

    const getProiecte = () => {
      axios.post('../action.php', {
        post_case: 'getProiecteById',
        id: props.id
      },
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }). then(function (response) {
        console.log(response.data);
        if(response.data.length > 0) {
          const uniqueAddresses = Array.from(new Set(response.data.map(a => a.pid)))
              .map(id => {
                return response.data.find(a => a.pid === id)
              })
          proiecte.value = uniqueAddresses;
          etape.value = response.data;
          getDocuments(uniqueAddresses[0].uuid);
        } else {
          console.warn('Nu sunt date');
        }

      })
    }

    const getDocuments = (uuid) =>{
      axios.post('../action.php', {
        post_case: 'getDoc',
        uuid: uuid
      },
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }). then(function (response) {
        console.log(response.data);

        docs.value = response.data;
      })
    }

    const getServiceList = () => {
      axios.post('../action.php', {
            post_case: 'servicetype'
          },
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(function (response){
        servicii.value = response.data;

        //getServiceByProiect(servicii.value);
      }).catch(function (response) {
        //handle error
        console.log(response);
      });
    }
    getServiceList();

    /*const getServiceByProiect = (s) => {
      console.log(`s ${s}`);
      s.forEach(a => {
        console.log(a);
        /!*axios.post('../action.php', {
            post_case: 'servicebyproiect',
            id: s
          },
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(function (response){
            console.log(response.data);
        servicebyproiect.value = response.data;
      }).catch(function (response) {
        //handle error
        console.log(response);
      });*!/
      });

    }*/

    const deleteProiect = (id) => {
      console.log(id);
    }

    const addNewProiect = () => {
      editData.value = {
        id: 0,
        name: '',
        startdate: '',
        enddate: ''
      };
      modalTitle.value = 'Adaugare';
    }

    const saveProiect = (e) => {
      axios.post('../action.php', {
        post_case: 'saveproiect',
        id: 0,
        uuid: uuids.value,
        idclient: props.id,
        name: e.name,
        startdate: e.startdate,
        enddate: e.enddate,
      }, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(function (response) {
        getProiecte();
      }).catch(function (error) {
        console.log(error);
      });
    }

    const addServices = (id) => {
      proiectid.value = id;
      servicii.value.forEach((item) => {
        addserv.value.push({
          value: item.id,
          text: item.name
        });
      });
    }

    const saveServici = (e) => {
      const p = servicii.value.find(s => s.id === e.value.selected);
      axios.post('../action.php', {
        post_case: 'saveaddservici',
        id: 0,
        id_service: e.value.selected,
        id_proiect: proiectid.value,
        id_client: props.id,
        start: e.value.startdate,
        total: p.price,
        platit: 0
      }, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(function (response){
        //getServiceList();
      }).catch(function (error){
        console.error(error);
      });
    }

    getProiecte();

    const getEtape = (id) => {
      return etape.value.filter(e => e.pid === id);
    }

    const getDocs = (id) => {
      return docs.value.filter(d => d.idetape === id);
    }

    const modifEtapa = () =>{
      console.log('modif');
    }

    const addDocument = (etapa) => {
      selectedEtapa.value = etapa;
    }

    const saveDocs = (e) => {
      axios.post('../action.php', {
        post_case: 'saveDocs',
        eid: e.id,
        uuid: e.uuid,
        name: e.nume,
        sdata: e.data
      }, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(function (response) {
        getProiecte();
      }).catch(function (error) {
        console.log(error);
      });
    }

    const text = "d;fgj;dlfkjg;df";
    return {
      text,
      client,
      proiecte,
      modalTitle,
      editData,
      addserv,
      uuids,
      etape,
      eid,
      euuid,
      tablefields,
      selectedEtapa,
      deleteProiect,
      addNewProiect,
      saveProiect,
      addServices,
      saveServici,
      getEtape,
      modifEtapa,
      addDocument,
      saveDocs,
      getDocs
    }
  }
});

</script>

<style lang="scss" scoped>
table.text-center * {
  text-align: center;
}

.etape {
  font-size: 18px;
  padding: 10px;



}
.activeClass {
  color: #ff0000;
}

.noactiveClass {
  color: #00ff00;
}
</style>
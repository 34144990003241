<template>
  <div class="btn-popup pull-right">
    <b-modal
        id="modal-1"
        :title ="title"
        @ok="onSave"
        ok-title="Save"
        ok-variant="primary"
    >
      <form class="needs-validation">
        <div class="form">
          <div class="form-group">
            <label for="validationCustom01" class="mb-1">Denumire etapa:</label>
            <input
                class="form-control"
                id="validationCustom01"
                type="text"
                v-model="etape.name"
            />
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script lang="ts">

import {computed, defineComponent, ref} from "vue";

export default defineComponent({
  name: "EtapeModal",
  components: {

  },
  props: {
    etapeData: {
      type: Object,
      required: true
    },
    title: String
  },
  setup(props, {emit}){
    const etape = computed(() => props.etapeData);

    const onSave = () => {
      emit('saveEtape', etape.value);
    }


    return {
      onSave,
      etape
    }
  }
});

</script>
<style>

</style>
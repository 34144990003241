<template>
  <div>
    <b-form-select v-model="selected" :options="options" @change="serviciSelected"></b-form-select>
    <div class="mt-3">Selected: <strong>{{ selected }}</strong></div>
  </div>

</template>

<script lang="ts">

import axios from "axios";
import {ref, defineComponent} from "vue";

export default defineComponent({
  name: "Servicii",
  props: {

  },
  setup(props, { emit }) {

    const selected = ref();
    const options = ref([]);

    const getServiceList = () => {
      axios.post('../action.php', {
            post_case: 'servicetype'
          },
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(function (response){

            response.data.forEach(p => {
              options.value.push({
                value: p.id,
                text: p.name
              });
            })
      }).catch(function (response) {
        //handle error
        console.log(response);
      });
    }
    getServiceList();

    const serviciSelected = () => {
      console.log(selected.value);

    }


    return {
      selected,
      options,
      serviciSelected
    }
  }
});

</script>

<style scoped>

</style>
<template>
  <div class="btn-popup pull-right">
    <b-modal
        id="modal-doc"
        :title ="title"
        @ok="onSave"
        ok-title="Save"
        ok-variant="primary"
    >
      <form class="needs-validation">
        <div class="form">
          <div class="form-group">
            <label for="validationCustom01" class="mb-1">Denumire document:</label>
            <input
                class="form-control"
                id="validationCustom01"
                type="text"
                v-model="nume"
            />
          </div>
          <div class="form-group">
            <label for="validationCustom01" class="mb-1">Data depunerii:</label>
            <b-form-datepicker
                id="startdate"
                v-model="startdate"
                locale="ro"
                placeholder="YYYY-MM-DD"
                type="text"
                autocomplete="off"
                :min="minDate"
                class="mb-2">
            </b-form-datepicker>
            <p>Value: '{{ startdate }}'</p>
          </div>

        </div>
      </form>
    </b-modal>
  </div>
</template>

<script lang="ts">

import {computed, defineComponent, ref} from "vue";

export default defineComponent({
  name: "DocumentModal",
  components: {

  },
  props: {
    etapa: {
      type: Object,
      required: true,
    },
    title: String
  },
  setup(props, {emit}){
    const dataDoc = ref();
    const nume = ref();
    const startdate = ref();
    const minDate = computed(()=> props.etapa.start);

    const onSave = () => {
      const dataSave = computed(() => {
        return {
          id: props.etapa.etapeId,
          uuid: props.etapa.uuid,
          nume: nume.value,
          data: startdate.value
        }
      })
      emit('saveDocs', dataSave.value);
    }


    return {
      dataDoc,
      startdate,
      nume,
      minDate,
      onSave
    }
  }
});

</script>
<style>

</style>
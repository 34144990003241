import { render, staticRenderFns } from "./Servicii.vue?vue&type=template&id=ba1b0e68&scoped=true"
import script from "./Servicii.vue?vue&type=script&lang=ts"
export * from "./Servicii.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba1b0e68",
  null
  
)

export default component.exports
<template>
  <div class="btn-popup pull-right">

    <b-modal
        id="modal-xl"
        :title.sync ="title"
        size="xl"
        @ok="onSave"
        ok-title="Save"
        ok-variant="primary"
    >
      <form class="row needs-validation">
        <div class="col-md-6 ">
          <label for="firstName" class="form-label">First Name</label>
          <input type="text" class="form-control" id="firstname" v-model="user.firstname">
        </div>
        <div class="col-md-6">
          <label for="lastName" class="form-label">Last Name</label>
          <input type="text" class="form-control" id="lastName" required="" v-model="user.lastname">
        </div>
        <div class="col-md-6">
          <label for="inputEmail4" class="form-label">Email</label>
          <input type="email" class="form-control" id="inputEmail4" v-model="user.email">
        </div>
        <div class="col-md-6">
          <label for="inputPhone4" class="form-label">Phone</label>
          <input type="text" class="form-control" id="inputPhone4" v-model="user.phone">
        </div>
        <div class="col-12">
          <label for="inputAddress" class="form-label">Address</label>
          <input type="text" class="form-control" id="inputAddress" v-model="user.address">
        </div>
        <div class="col-12" style="width: 100%; background-color: #404040; height: 1px; margin: 15px 0 15px 0;"></div>

        <div class="col-12" style="border: solid 1px #404040; padding: 10px; margin: 15px; flex: 0 0 97%">
          <div style="padding-bottom: 15px;">COMPANY INFO</div>
          <div class="row">
            <div class="col-4">
              <label for="inputCompanyName" class="form-label">Company Name</label>
              <input type="text" class="form-control" id="inputCompanyName" v-model="user.comp">
            </div>
            <div class="col-4">
              <label for="inputVatNumber" class="form-label">VAT Number</label>
              <input type="text" class="form-control" id="inputVatNumber" v-model="user.vat">
            </div>
            <div class="col-4">
              <label for="inputRegistNumber" class="form-label">Registration Number</label>
              <input type="text" class="form-control" id="inputRegistNumber" v-model="user.cui">
            </div>
            <div class="col-6">
              <label for="inputBank" class="form-label">Bank Name</label>
              <input type="text" class="form-control" id="inputBank" v-model="user.bank">
            </div>
            <div class="col-6">
              <label for="inputIban" class="form-label">IBAN</label>
              <input type="text" class="form-control" id="inputIban" v-model="user.iban">
            </div>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script lang="ts">

import { defineComponent, computed} from "vue";

export default defineComponent({
  name: "ClientModal",
  props: {
    client: Object | null,
    title: ''
  },

  setup(props, { emit }) {

    const user = computed(()=> {
      console.log(props.client);
      return props.client;
    });

    const onSave = () => {
      const formDataM =
        {
          id: user.value?.id,
          lastname: user.value?.lastname,
          firstname: user.value?.firstname,
          email: user.value.email,
          phone: user.value.phone,
          address: user.value.address,
          comp: user.value.comp,
          vat: user.value.vat,
          cui: user.value.cui,
          bank: user.value.bank,
          iban: user.value.iban
        };
      emit("formDataM", formDataM);

    };


    return {
      user,
      onSave
    }
  }
});
</script>

<style>

</style>
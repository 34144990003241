<template>
  <div class="btn-popup pull-right">
    <b-modal
        id="modal-1"
        :title ="title"
        @ok="onSave"
        ok-title="Save"
        ok-variant="primary"
    >
      <form class="needs-validation">
        <div class="form">
          <div class="form-group">

            <label for="validationCustom01" class="mb-1">Denumire proiect:</label>
            <input
                class="form-control"
                id="validationCustom01"
                type="text"
                v-model="proiect.name"
            />
          </div>
          <div class="form-group">
            <label for="startdate">Start date</label>
            <b-form-datepicker
                id="startdate"
                v-model="proiect.startdate"
                locale="ro"
                placeholder="YYYY-MM-DD"
                type="text"
                autocomplete="off"
                class="mb-2">

            </b-form-datepicker>
            <p>Value: '{{ proiect.startdate }}'</p>
          </div>
          <div class="form-group">
            <label for="enddate">End date</label>
            <b-form-datepicker
                id="enddate"
                v-model="proiect.enddate"
                locale="ro"
                class="mb-2">

            </b-form-datepicker>
            <p>Value: '{{ proiect.enddate }}'</p>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script lang="ts">

import {computed, defineComponent, ref} from "vue";

export default defineComponent({
  name: "ProiectModal",
  components: {

  },
  props: {
    proiectData: {
      type: Object,
      required: true
    },
    title: String
  },
  setup(props, {emit}){
    const proiect = computed(() => props.proiectData);

    const onSave = () => {
      emit('saveProiect', proiect.value);
    }


    return {
      onSave,
      proiect
    }
  }
});

</script>
<style>

</style>
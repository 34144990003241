import Vue from 'vue'
import VueRouter from 'vue-router'
import Body from '../components/Body.vue';
import dashboard from '../pages/dashboard.vue';
import Media from '../pages/media.vue';
import UserList from '../pages/users/user-list.vue';
import CreateUser from '../pages/users/create-user.vue';
import Reports from '../pages/reports.vue';
import Invoice from '../pages/invoice.vue';
import Auth from '../pages/authentication/index.vue';
import ServiceType from "@/pages/service/service-type.vue";
import ClientsList from "@/pages/clients/clients-list.vue";
import ClientDetails from "@/pages/clients/client-details.vue";
import Etape from '../pages/service/etape.vue';
import Gantt from '../pages/service/gantt.vue';
import CalendarPage from '../pages/service/calendarPage.vue';
import Proiecte from '../pages/proiecte/proiecte.vue';

Vue.use(VueRouter)

const routes = [
    { path: '', redirect: { name: 'default' } },
    {
        path: '/dashboard',
        component: Body,
        children: [{
            path: 'default',
            name: 'default',
            component: dashboard,
            meta: {
                title: 'OverView',
            }
        }]
    },

    {
        path: '/service',
        name: 'service',
        component: Body,
        children: [
            {
                path: 'service-type',
                name: 'service-type',
                component: ServiceType,
                meta: {
                    title: 'Servicii'
                    }
            },
            {
                path: 'etape',
                name: 'etape',
                component: Etape,
                meta: {
                    title: 'Etape'
                }
            },
            {
                path: 'gantt',
                name: 'gantt',
                component: Gantt,
                meta: {
                    title: 'Gantt'
                }
            },
            {
                path: 'calendarPage',
                name: 'calendar',
                component: CalendarPage,
                meta: {
                    title: 'CalendarPage'
                }
            }
        ]
    },

    {
        path: '/clients',
        name: 'clients',
        component: Body,
        children: [{
            path: 'clients-list',
            name: 'clients-list',
            component: ClientsList,
            meta: {
                title: 'Clients List'
            }
        }
        ]
    },

    {
        path: '/client-details/:id',
        name: 'client-details',
        component: Body,
        children: [{
            path: '/client-details/:id',
            name: 'clients-details',
            component: ClientDetails,
            props: true,
            meta: {
                title: 'Clients Details'
            }
        }
        ]
    },

    {
        path: '/users',
        name: 'users',
        component: Body,
        children: [{
                path: 'user-list',
                name: 'user-list',
                component: UserList,
                meta: {
                    title: 'User',

                }
            },
            {
                path: 'create-user',
                name: 'create-user',
                component: CreateUser,
                meta: {
                    title: 'Create User',

                }
            }
        ]
    },
    {
        path: '/proiecte',
        name: 'proiecte',
        children: [{
            path: 'proiect-list',
            name: 'proiect-list',
            component: Proiecte,
            meta: {
                title: 'Proiecte'
            }
        }
        ]
    },

    {
        path: '/auth/login',
        name: 'login',
        component: Auth,
        meta: {
            title: 'Login',

        }
    },
    {
        path: '/app',
        component: Body,
        children: [{
                path: 'media',
                name: 'media',
                component: Media,
                meta: {
                    title: 'Media',
                }
            },
            {
                path: 'reports',
                name: 'reports',
                component: Reports,
                meta: {
                    title: 'Reports',

                }
            },
            {
                path: 'invoice',
                name: 'invoice',
                component: Invoice,
                meta: {
                    title: 'Invoice',

                }
            },
        ],
    }
]


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, from, next) => {
     
    const  path = ['/auth/login','/auth/register'];
    if (path.includes(to.path) || localStorage.getItem('user')  ){
      return next();
    }
    next('/auth/login');
  });

export default router
<template>
  <div>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs main="Digital" title="Clients list" />
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>Clients list</h5>
            </div>
            <div class="card-body">
              <ClientModal
                  @formDataM="formDataM"
                  :client="editData"
                  :title="modalTitle"
              />
              <b-button v-b-modal.modal-xl :variant="'primary'" @click="addNewClient()">Add new Client</b-button>
            </div>
            <div class="table-responsive datatable-vue product-physical">
              <b-modal id="modal-2" title="Confirmare" @ok="deleteRow(uuid)">
                <p class="my-4">Are you sure! {{uuid}}</p>
              </b-modal>
              <b-table
                  :select-mode="selectMode"
                  class="text-center"
                  striped
                  hover
                  head-variant="dark"
                  bordered
                  show-empty
                  stacked="md"
                  :fields="tablefields"
                  :items="items"
                  :selectable="true"
                  :current-page="currentPage"
                  :per-page="perPage"
                  @row-selected="rowSelected"
              >


                <template v-slot:cell(actions)="{item: { id, firstname, lastname, email, phone, address, comp, vat, cui, bank, iban} }">
                    <span
                        style="font-size: 20px; color: blue;"
                        v-b-modal.modal-xl
                        @click="onEdit(id)"
                    >
                      <feather
                          type="edit-2"
                          stroke="#3758FD"
                          stroke-width="1"
                          size="18px"
                          fill="#3758FD"
                          stroke-linejoin="round"
                      ></feather>
                    </span>
                    <span
                        v-b-modal.modal-2
                        @click="getIndex(id)"
                        style="font-size: 20px; color: #FA399F;"
                    >
                      <feather
                          type="trash"
                          stroke="#F72E9F"
                          size="18px"
                          fill="#F72E9F"
                      ></feather>
                    </span>

                </template>
                <template v-slot:cell(details)="{item: { id, firstname, lastname }}">
                  <router-link :to="`/client-details/${id}`">
                    <button type="button" class="btn btn-outline-info btn-sm">Detalii</button>

                  </router-link>
                </template>

              </b-table>
              <b-col md="12" class="my-1 pagination-justify p-0">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    aria-controls="my-table"
                    class="mt-4"
                ></b-pagination>
              </b-col>

            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script lang="ts">
import {defineComponent, ref} from "vue";
import statusIcon from "../../components/featherIcons/status-icon.vue";
import axios from "axios";
import ClientModal from "@/components/modals/clientModal.vue";

export default defineComponent({
  name: "ClientsList",
  components: {
    statusIcon,
    ClientModal
  },
  props: {},
  setup() {
    const editData = ref({
      firstname: 'aa'
    });
    const items = ref();
    const filter = null;
    const selectMode = "single";
    const currentPage = ref(1);
    const totalRows = ref();
    const perPage = ref(10);
    const uuid = ref();
    const modalTitle = ref();

    const getClient = () => {
      axios.post('../action.php', {
        post_case: 'clients'
      }, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(function (response) {
        items.value = response.data;
        totalRows.value = response.data.length;
      });
    }
    getClient();
    const formDataM = (e) =>{
      axios.post('../action.php', {
        post_case: 'saveclient',
        id: 0,
        firstname: e.firstname?e.firstname:'',
        lastname: e.lastname?e.lastname:'',
        email: e.email?e.email:'',
        phone: e.phone?e.phone:'',
        address: e.address?e.address:'',
        comp: e.comp?e.comp:'',
        vat: e.vat?e.vat:'',
        cui: e.cui?e.cui:'',
        bank: e.bank?e.bank:'',
        iban: e.iban?e.iban:'',
      }, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(function (response) {
        getClient();
      }).catch(function (error) {
        console.log(error);
      });
    };

    const getIndex = (id) => {
      uuid.value=id;
    }

    const onEdit = (id) => {
      editData.value = items.value.find(item => item.id === id);
      modalTitle.value = "Editare";
    }

    const deleteRow = (id) => {
      axios.post('../action.php', {
        post_case: 'deleteclient',
        id: id
      }, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(function (response) {
        getClient();
      }).catch(function (error) {
        console.log(error);
      });
    };
    const tablefields = [
      { key: "firstname", label: "First Name", sortable: true },
      { key: "lastname", label: "Last name", sortable: true },
      { key: "email", label: "Email", sortable: true },
      { key: "phone", label: "Phone", sortable: false },
      { key: "address", label: "Address", sortable: false },
      { key: "comp", label: "Company", sortable: false },
      { key: "actions" },
      { key: "details" }
    ];
    const rowSelected = (item) => {
      console.log(item);
      //return items.value.filter(item => item.delete);
    };

    const addNewClient = () => {
      modalTitle.value = "Adaugare";
      editData.value = {
        firstname: ''
      };
    }


    return {
      selectMode,
      editData,
      items,
      tablefields,
      modalTitle,
      filter,
      currentPage,
      totalRows,
      perPage,
      uuid,
      formDataM,
      deleteRow,
      rowSelected,
      getIndex,
      onEdit,
      addNewClient
    }
  }
});

</script>

<style lang="scss" scoped>
table.text-center * {
  text-align: center;
}
</style>
<template>
  <div>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs main="Services" title="Etape" />
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>Etape</h5>
            </div>
            <div class="card-body">
              <EtapeModal
                  @saveEtape="saveEtape"
                  :etapeData="editData"
                  :title="modalTitle"
              />
              <b-button v-b-modal.modal-1 :variant="'primary'" @click="addNewEtape()">Add new Etape</b-button>
            </div>
            <div class="table-responsive datatable-vue product-physical">
              <b-modal id="modal-2" title="Confirmare" @ok="deleteRow(uuid)">
                <p class="my-4">Are you sure! {{uuid}}</p>
              </b-modal>
              <b-table
                  class="text-center"
                  striped
                  hover
                  head-variant="light"
                  bordered
                  show-empty
                  stacked="md"
                  :fields="tablefields"
                  :items="etape"
                  :selectable="true"
                  :current-page="currentPage"
                  :per-page="perPage"
              >
                <template v-slot:cell(actions)="{item: { id, name} }">
                  <span
                      style="font-size: 20px; color: blue;"
                      v-b-modal.modal-1
                      @click="onEdit(id)"
                  >
                    <feather
                        type="edit-2"
                        stroke="#3758FD"
                        stroke-width="1"
                        size="18px"
                        fill="#3758FD"
                        stroke-linejoin="round"
                    ></feather>
                  </span>
                  <span
                      v-b-modal.modal-2
                      @click="getIndex(id)"
                      style="font-size: 20px; color: #FA399F;"
                  >
                    <feather
                        type="trash"
                        stroke="#F72E9F"
                        size="18px"
                        fill="#F72E9F"
                    ></feather>
                  </span>

                </template>
              </b-table>
              <b-col md="12" class="my-1 pagination-justify p-0">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    aria-controls="my-table"
                    class="mt-4"
                ></b-pagination>
              </b-col>
            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script lang="ts">
import {defineComponent, ref} from "vue";
import statusIcon from "../../components/featherIcons/status-icon.vue";
import axios from "axios";
import EtapeModal from "@/components/modals/EtapeModal.vue";

export default defineComponent({
  name: "Etape",
  components: {
    EtapeModal,
    statusIcon,
  },
  props: {

  },
  setup() {
    const etape = ref();
    const editData = ref({});
    const modalTitle = ref();
    const uuid = ref();
    const currentPage = ref(1);
    const perPage = ref(10);
    const totalRows = ref();
    const tablefields = ref([
        { key: "id", label: "Id" },
      { key: "name", label: "Name", sortable: true },
      { key: "actions"}
    ]);

    const getEtape = () => {
      axios.post('../action.php', {
        post_case: 'getEtape'
      },
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }). then(function (response) {
        etape.value = response.data;
        totalRows.value = response.data.length;
      })
    }
    getEtape();

    const addNewEtape = () => {
      modalTitle.value = "Adaugare etapa";
      editData.value = {};
    }

    const saveEtape = (e) => {
      axios.post('../action.php', {
        post_case: 'saveEtape',
        id: e.id?e.id:0,
        name: e.name
      }, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(function (response) {
        getEtape();
      }).catch(function (error) {
        console.log(error);
      });
    }

    const deleteRow = (id) => {
      axios.post('../action.php', {
        post_case: 'deleteEtape',
        id: id
      }, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(function (response) {
        getEtape();
      }).catch(function (error) {
        console.log(error);
      });
    }

    const onEdit = (id) =>{
      editData.value = etape.value.find(i => i.id === id);
      modalTitle.value = "Editare";
    }

    const getIndex = (id) => {
      uuid.value=id;
    }

    return {
      etape,
      editData,
      modalTitle,
      uuid,
      tablefields,
      currentPage,
      perPage,
      totalRows,
      saveEtape,
      deleteRow,
      onEdit,
      getIndex,
      addNewEtape
    }
  }
});

</script>

<style lang="scss" scoped>
table.text-center * {
  text-align: center;
}
</style>
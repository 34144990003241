<template>
  <div>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs main="Services" title="Servicii" />
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>Servicii</h5>
            </div>
            <div class="card-body">
              <GlobalCategoryModal
                  @formData="saveService"
                  :serviceType="editData"
                  :title="modelTitle"
              />
              <b-button v-b-modal.modal-1 :variant="'primary'" @click="addNewData()">Add service type</b-button>
            </div>
            <div class="table-responsive datatable-vue product-physical">
              <b-modal id="modal-2" title="Confirmare" @ok="deleteRow(uuid)">
                <p class="my-4">Are you sure! {{ uuid }}</p>
              </b-modal>
              <b-table
                  class="text-center"
                  striped
                  hover
                  head-variant="light"
                  bordered
                  show-empty
                  stacked="md"
                  :items="items"
                  :fields="tableFields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :selectable="true"
              >

                <template #cell(image)="field">
                  <img
                      height="150px"
                      :src="field.item.img"
                  />
                </template>

                <template v-slot:cell(actions)="{ item: { id } }">
                  <span
                      style="font-size: 20px; color: blue;"
                      v-b-modal.modal-1
                      @click="onEdit(id)"
                  >
                    <feather
                        type="edit-2"
                        stroke="#3758FD"
                        stroke-width="1"
                        size="18px"
                        fill="#3758FD"
                        stroke-linejoin="round"
                    ></feather>
                  </span>
                  <span
                      v-b-modal.modal-2
                      @click="getIndex(id)"
                      style="font-size: 20px; color: #FA399F;"
                  >
                    <feather
                        type="trash"
                        stroke="#F72E9F"
                        size="18px"
                        fill="#F72E9F"
                    ></feather>
                  </span>
                </template>

              </b-table>

              <b-col md="12" class="my-1 pagination-justify p-0">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    aria-controls="my-table"
                    class="mt-4"
                ></b-pagination>
              </b-col>
            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script lang="ts">

import {computed, defineComponent, ref} from "vue";
import axios from "axios";

export default defineComponent({
  name: "ServiceType",
  components: {

  },
  props: {},
  setup(){
    const items = ref();
    const totalRows = ref();
    const uuid = ref();
    const modelTitle = ref();
    const currentPage = ref(1);
    const perPage = ref(10);

    const tableFields = ref([
      { key: "id", label: "Service id", sortable: false },
      { key: "name", label: "Service name", sortable: true },
      { key: "unit", label: "Service unit", sortable: true },
      { key: "price", label: "Price", sortable: false },
      { key: "tva", label: "TVA", sortable: false },
      { key: "total", label: "Total", sortable: false },
      { key: "actions" }
    ]);

    const editData = ref({});



    const addNewData = () => {
      editData.value = {};
      modelTitle.value = "Adaugare";
    }

    const saveService = (v) => {
      axios.post('../action.php', {
        post_case: 'saveservicetype',
        id: v.id?v.id:0,
        name: v.name,
        unit: v.unit,
        price: v.price
      }, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(function (response){
        getServiceList();
      }).catch(function (error){
        console.error(error);
      });
    }

    const onEdit = (id) => {
      editData.value = items.value.find(i => i.id === id);
      modelTitle.value = "Editare";
    }

    const deleteRow = (id) => {
      axios.post('../action.php', {
        post_case: 'deleteservicetype',
        id: id
      }, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(function (response) {
        getServiceList();
      }).catch(function (error) {
        console.log(error);
      });
    }

    const getIndex = (id) => {
      uuid.value=id;
    }

    return {
      editData,
      modelTitle,
      uuid,
      items,
      tableFields,
      currentPage,
      perPage,
      totalRows,
      addNewData,
      saveService,
      deleteRow,
      onEdit,
      getIndex
    }
  }
});
</script>

<style lang="scss" scoped>
table.text-center * {
  text-align: center;
}
</style>
<template>
  <div>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs main="Services" title="Calendar" />
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>Calendar</h5>
            </div>
          </div>

        </div>
        <div class="col-md-12" style="display: flex">
          <div class="col-md-10">
            <div class='demo-app-main'>
              <FullCalendar
                  class='demo-app-calendar'
                  :options='calendarOptions'
              >
                <template v-slot:eventContent='arg'>
                  <b>{{ arg.timeText }}</b>
                  <i>{{ arg.event.title }}</i>
                </template>
              </FullCalendar>
            </div>
          </div>
          <div class="col-md-2">
            <div class='demo-app-sidebar'>
              <div class='demo-app-sidebar-section'>
                <label>
                  <input
                      type='checkbox'
                      :checked='calendarOptions.weekends'
                      @change='handleWeekendsToggle'
                  />
                  toggle weekends
                </label>
              </div>
              <div class='demo-app-sidebar-section'>
                <h2>All Events ({{ currentEvents.length }})</h2>
                <ul>
                  <li v-for='event in currentEvents' :key='event.id'>
                    <b>{{ event.startStr }}</b>
                    <i>{{ event }}</i>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, ref} from "vue";
import statusIcon from "../../components/featherIcons/status-icon.vue";
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import axios from "axios";
import {Calendar} from "@fullcalendar/core";

export default defineComponent({
  name: "CalendarPage",
  components: {
    statusIcon,
    FullCalendar
  },
  props: {

  },
  setup(props, { emit }) {
    let calendarOptions = ref(Object);

    const currentEvents = ref(Array);
    const proiecte = [];

    const getProiecte = () => {
      axios.post('../action.php', {
          post_case: 'getProiecteById',
          id: 0
        },
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }). then(function (response) {
          console.log(response.data);
          response.data.forEach(p => {
            calendarOptions.value.events.push(
                {
                  id: p.id,
                  title: p.name,
                  start: p.startdate,
                  end: p.enddate,
                  allDay: true
                }
            )

          })
      })

    }
    getProiecte();

    currentEvents.value = [
      {
        id:101,
        title:'Event 1',
        start: '2024-12-12',
        end: '2024-12-20',
        allDay: true
      },
      {
        id:201,
        title:'Event blaa on same day!',
        start: '2024-12-17',
        allDay: true
      }
    ];


    const createEventId = () => {
      return currentEvents.value.length+1;
    }

    const handleDateSelect = (selectInfo) => {
      console.log(selectInfo);
      let title = prompt('Please enter a new title for your event')
      let calendarApi = selectInfo.view.calendar

      calendarApi.unselect() // clear date selection

      if (title) {
        calendarApi.addEvent({
          id: createEventId(),
          title,
          start: selectInfo.startStr,
          end: selectInfo.endStr,
          allDay: selectInfo.allDay
        })
      }
      return calendarApi;
    }

    const handleEventClick = (clickInfo) => {
      if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
        console.log(clickInfo.event.id);
        clickInfo.event.remove();
        currentEvents.value.splice(currentEvents.value.indexOf(clickInfo.event.id), 1);
      }
    }

    const handleEvents = (events) => {
      currentEvents.value = events;
    }

    const handleWeekendsToggle = () => {
      calendarOptions.value.weekends = !calendarOptions.value.weekends;
    }

    calendarOptions.value = {
      plugins: [
        dayGridPlugin,
        timeGridPlugin,
        interactionPlugin // needed for dateClick
      ],
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay'
      },
      initialView: 'dayGridMonth',
      events: currentEvents.value,
      //initialEvents: currentEvents.value, // alternatively, use the `events` setting to fetch from a feed
      editable: true,
      selectable: true,
      selectMirror: true,
      dayMaxEvents: true,
      weekends: true,
      select: handleDateSelect,
      eventClick: handleEventClick,
      eventsSet: handleEvents
    }

    return {
      calendarOptions,
      handleWeekendsToggle,
      currentEvents
    }
  }
});

</script>

<style lang="css" scoped>
h2 {
  margin: 0;
  font-size: 16px;
  color: #000000 !important;
}

ul {
  margin: 0;
  padding: 0 0 0 1.5em;
}

li {
  margin: 1.5em 0;
  padding: 0;
}

b { /* used for event dates/times */
  margin-right: 3px;
}

li {
  color: #000000 !important;
}

label {
  color: #000000 !important;
}

.demo-app {
  display: flex;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

.demo-app-sidebar {
  width: 300px;
  line-height: 1.5;
  background: #eaf9ff;
  border-right: 1px solid #d3e2e8;
}

.demo-app-sidebar-section {
  padding: 2em;

}

.demo-app-main {
  flex-grow: 1;
  padding: 3em;
}

.demo-app-calendar{
  color: #ffffff;
}

.fc { /* the calendar root */
  max-width: 1100px;
  margin: 0 auto;
}
</style>
import axios from 'axios';
import config from '../../../config.json'
import item from "vee-validate/dist/rules.umd";
const ax = axios.create({
    baseURL: config.baseUrl,
});
const url = 'action.php';

const state = {
    clientsList: []
}

const getters = {
    getClientsList: (state) => {
        return state.clientsList;
    }
}

const actions = {
    getClientsList(context) {
        let formData = new FormData();
        formData.append('post_case', 'clients');
        axios({
            method: 'post',
            url: '../action.php',
            data: formData,
            config: {headers: {'Content-Type': 'multipart/form-data'}}
        })
            .then(function (response) {
                console.log(response);
                context.commit('getClientsList', response);
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
    },
    saveClient: (context, item) => {
        let formData = new FormData();
        formData.append('post_case', 'saveclient');
        formData.append('id', item.id);
        formData.append('firstname', item.firstnamem);
        formData.append('lastname', item.lastnamem);
        formData.append('email', item.emailm);
        formData.append('phone', item.phonem);
        formData.append('address', item.addressm);
        formData.append('comp', item.compm);
        formData.append('vat', item.vatm);
        formData.append('cui', item.cuim);
        formData.append('bank', item.bankm);
        formData.append('iban', item.ibanm);

        return axios({
            method: 'post',
            url: '../action.php',
            data: formData,
            config: {headers: {'Content-Type': 'multipart/form-data'}}
        })
            .then(function (response) {

            })
            .catch(function (response) {
                console.log(response);
            });
    },
    deleteClient: (context, item) => {
        let formData = new FormData();
        formData.append('post_case', 'deleteclient');
        formData.append('id', item);
        return axios({
            method: 'post',
            url: '../action.php',
            data: formData,
            config: {headers: {'Content-Type': 'multipart/form-data'}}
        })
            .then(function (response) {

            })
            .catch(function (response) {
                console.log(response);
            });
    }

}

const mutations = {
    getClientsList(state, payload) {
        state.clientsList = payload.data;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
<template>
<!--  <form class="form-horizontal auth-form" @submit.prevent="" method="post">
    <div class="form-group">
      <input v-model="form.email" name="login[username]" type="email" class="form-control" id="exampleInputEmail1" />
    </div>
    <div class="form-group">
      <input :type="type" v-model="form.password" name="login[password]" class="form-control" />
    </div>
    <div class="form-terms">
&lt;!&ndash;      <div class="custom-control custom-checkbox mr-sm-2">

        <a href="#" class="btn btn-default forgot-pass">lost your password</a>
      </div>&ndash;&gt;
    </div>
    <div class="form-button">
      <button class="btn btn-primary" type="submit" :disabled="form.dis" @click="doLogin">
        Login
      </button>
    </div>

  </form>-->
  <ValidationObserver v-slot="{ invalid }">
    <form @submit.prevent="doLogin">
      <ValidationProvider name="E-mail" rules="required|email" v-slot="{ errors }">
        <div class="form-group">
          <input v-model="form.email" name="login[username]" type="email" class="form-control" id="exampleInputEmail1" />
          <span>{{ errors[0] }}</span>
        </div>
      </ValidationProvider>

      <ValidationProvider name="Password" rules="required|alpha_dash" v-slot="{ errors }">
        <input :type="type" v-model="form.password" name="login[password]" class="form-control" />
        <span>{{ errors[0] }}</span>
      </ValidationProvider>

      <button type="submit" :disabled="invalid">Submit</button>
    </form>
  </ValidationObserver>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      type: 'password',
      form: {
        email: "",
        password: "",
        dis: true,
      }

    }
  },
  created() {
    this.$store.dispatch("user/getLogin");
  },
  computed: {
    ...mapGetters({
      items: "user/getLogin"
    }),

  },
  methods: {
    showPassword: function () {
      if (this.type === 'password') {
        this.type = 'text';
      } else {
        this.type = 'password';
      }
    },
    async doLogin() {
      await this.$store.dispatch("user/getLogin", this.form).then((res) => {
        if(this.items === 'ok'){
          localStorage.setItem('user', this.form.email)
          this.$router.push('/');
        } else {
          this.$toasted.show('Oops...' + err.message, { theme: 'bubble', position: "bottom-right", type: 'error', duration: 2000 });
        }

      })
      .catch((error) => {
        alert(error);
      });
    },

  }
}
</script>